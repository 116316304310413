import * as routes from 'api';
import { pollWorkerStatus } from 'api/workerStatus';

import { showModal } from 'actions/modals';

import { getCanViewWages } from 'selectors/session';
import { getTeamDataIsLoading, getTeamDataLoaded } from 'selectors/teamView';

import { BULK_TERMINATE_MODAL_TYPE } from 'features/teamView/constants';

import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import {
  createAsyncDeleteAction,
  createAsyncGetAction,
  createAsyncPatchAction,
  createAsyncPostAction,
  withAlerts,
} from 'util/redux';
import { browserHistory } from 'util/router';
import { checkForServerErrors } from 'util/serverError';

import { fetchJSON } from '../api/fetch';

// # ACTION TYPES
export const actionTypes = {
  // ## Invite Team
  INVITE_TEAM_REQUEST: 'TEAM_VIEW/INVITE_TEAM_REQUEST',
  INVITE_TEAM_SUCCESS: 'TEAM_VIEW/INVITE_TEAM_SUCCESS',
  INVITE_TEAM_FAILURE: 'TEAM_VIEW/INVITE_TEAM_FAILURE',
  INVITE_TEAM_UPDATE_AUDIENCE: 'TEAM_VIEW/INVITE_TEAM_UPDATE_AUDIENCE',
  // ## Resend Employee Invite
  RESEND_EMPLOYEE_INVITE_REQUEST: 'TEAM_VIEW/RESEND_EMPLOYEE_INVITE_REQUEST',
  RESEND_EMPLOYEE_INVITE_SUCCESS: 'TEAM_VIEW/RESEND_EMPLOYEE_INVITE_SUCCESS',
  RESEND_EMPLOYEE_INVITE_FAILURE: 'TEAM_VIEW/RESEND_EMPLOYEE_INVITE_FAILURE',
  // ## Resolve Pending Jobs
  APPROVE_PENDING_JOB_REQUEST: 'TEAM_VIEW/APPROVE_PENDING_JOB_REQUEST',
  APPROVE_PENDING_JOB_SUCCESS: 'TEAM_VIEW/APPROVE_PENDING_JOB_SUCCESS',
  APPROVE_PENDING_JOB_FAILURE: 'TEAM_VIEW/APPROVE_PENDING_JOB_FAILURE',
  DECLINE_PENDING_JOB_REQUEST: 'TEAM_VIEW/DECLINE_PENDING_JOB_REQUEST',
  DECLINE_PENDING_JOB_SUCCESS: 'TEAM_VIEW/DECLINE_PENDING_JOB_SUCCESS',
  DECLINE_PENDING_JOB_FAILURE: 'TEAM_VIEW/DECLINE_PENDING_JOB_FAILURE',
  // ## Roster Data
  ROSTER_DATA_REQUEST: 'TEAM_VIEW/ROSTER_DATA_REQUEST',
  ROSTER_DATA_SUCCESS: 'TEAM_VIEW/ROSTER_DATA_SUCCESS',
  ROSTER_DATA_FAILURE: 'TEAM_VIEW/ROSTER_DATA_FAILURE',
  // ## UI
  TOGGLE_SHOW_REMOVED: 'TEAM_VIEW/TOGGLE_SHOW_REMOVED',
  SHOW_REMOVED: 'TEAM_VIEW/SHOW_REMOVED',
  HIDE_REMOVED: 'TEAM_VIEW/HIDE_REMOVED',
  FETCH_PAGINATED_USERS_REQUEST: 'TEAM_VIEW/FETCH_PAGINATED_USERS_REQUEST',
  FETCH_PAGINATED_USERS_SUCCESS: 'TEAM_VIEW/FETCH_PAGINATED_USERS_SUCCESS',
  FETCH_PAGINATED_USERS_FAILURE: 'TEAM_VIEW/FETCH_PAGINATED_USERS_FAILURE',
  FETCH_EMPLOYEES_FOR_IMPORT_REQUEST:
    'TEAM_VIEW/FETCH_EMPLOYEES_FOR_IMPORT_REQUEST',
  FETCH_EMPLOYEES_FOR_IMPORT_SUCCESS:
    'TEAM_VIEW/FETCH_EMPLOYEES_FOR_IMPORT_SUCCESS',
  FETCH_EMPLOYEES_FOR_IMPORT_FAILURE:
    'TEAM_VIEW/FETCH_EMPLOYEES_FOR_IMPORT_FAILURE',
  INITIATE_IMPORT_EMPLOYEES_REQUEST:
    'TEAM_VIEW/INITIATE_IMPORT_EMPLOYEES_REQUEST',
  INITIATE_IMPORT_EMPLOYEES_SUCCESS:
    'TEAM_VIEW/INITIATE_IMPORT_EMPLOYEES_SUCCESS',
  INITIATE_IMPORT_EMPLOYEES_FAILURE:
    'TEAM_VIEW/INITIATE_IMPORT_EMPLOYEES_FAILURE',
  IMPORT_EMPLOYEES_FINISHED: 'TEAM_VIEW/IMPORT_EMPLOYEES_FINISHED',
  UPDATE_MATCHED_EMPLOYEE: 'TEAM_VIEW/UPDATE_MATCHED_EMPLOYEE',
  UPDATE_LOADING_PAGINATED_USERS: 'TEAM_VIEW/UPDATE_LOADING_PAGINATED_USERS',
  UPDATE_ROLES_WAGES: 'TEAM_VIEW/UPDATE_ROLES_WAGES',
  IMPORT_EMPLOYEES_FINISHED_AFTER_EXPORT:
    'TEAM_VIEW/IMPORT_EMPLOYEES_FINISHED_AFTER_EXPORT',
  UPDATE_MATCHED_EMPLOYEE_WAGE: 'TEAM_VIEW/UPDATE_MATCHED_EMPLOYEE_WAGE',
  UPDATE_APPLY_WAGE_TO_ALL_MATCHES:
    'TEAM_VIEW/UPDATE_APPLY_WAGE_TO_ALL_MATCHES',
  REMOVE_MATCHED_EMPLOYEE: 'TEAM_VIEW/REMOVE_MATCHED_EMPLOYEE',
  UPDATE_QUERY_PARAMS: 'UPDATE_QUERY_PARAMS',
  TERMINATE_EMPLOYEE: 'TERMINATE_EMPLOYEE',
};

export const showBulkTerminateModal = () =>
  showModal(BULK_TERMINATE_MODAL_TYPE, {
    deprecatedModal: true,
    fullScreen: true,
    hideCloseIcon: true,
  });

export const updateRolesWages = payload => ({
  type: actionTypes.UPDATE_ROLES_WAGES,
  payload,
});

// # ACTIONS
// ## Resend Employee Invite
const resendEmployeeInviteAction = userId =>
  createAsyncGetAction(routes.employeesResendInviteRoute(userId), [
    { type: actionTypes.RESEND_EMPLOYEE_INVITE_REQUEST, meta: { userId } },
    { type: actionTypes.RESEND_EMPLOYEE_INVITE_SUCCESS, meta: { userId } },
    { type: actionTypes.RESEND_EMPLOYEE_INVITE_FAILURE, meta: { userId } },
  ]);

export const resendEmployeeInvite = userId => dispatch =>
  checkForServerErrors(
    resendEmployeeInviteAction(userId),
    "Couldn't resend invite."
  )(dispatch);

// ## Resolve Pending Jobs
export const approvePendingJob = (jobId, existingUserId) =>
  createAsyncPatchAction(routes.jobApproveRoute(jobId), [
    { type: actionTypes.APPROVE_PENDING_JOB_REQUEST, meta: { jobId } },
    {
      type: actionTypes.APPROVE_PENDING_JOB_SUCCESS,
      meta: { jobId, existingUserId },
    },
    { type: actionTypes.APPROVE_PENDING_JOB_FAILURE, meta: { jobId } },
  ]);

export const declinePendingJob = jobId =>
  createAsyncDeleteAction(routes.jobRoute(jobId), [
    { type: actionTypes.DECLINE_PENDING_JOB_REQUEST, meta: { jobId } },
    { type: actionTypes.DECLINE_PENDING_JOB_SUCCESS, meta: { jobId } },
    { type: actionTypes.DECLINE_PENDING_JOB_FAILURE, meta: { jobId } },
  ]);

export const resolvePendingJob =
  (jobId, approve, existingUserId) => dispatch => {
    const action = approve ? approvePendingJob : declinePendingJob;
    const errorMessage = approve
      ? "Couldn't approve request."
      : "Couldn't decline request.";

    return checkForServerErrors(
      action(jobId, existingUserId),
      errorMessage
    )(dispatch);
  };

// ## Roster Data
export const fetchPaginatedUsers = page => dispatch => {
  dispatch(
    withAlerts(
      createAsyncGetAction(routes.paginatedUsersRoute(page), [
        actionTypes.FETCH_PAGINATED_USERS_REQUEST,
        actionTypes.FETCH_PAGINATED_USERS_SUCCESS,
        actionTypes.FETCH_PAGINATED_USERS_FAILURE,
      ])
    )
  );
};

export const fetchRosterData =
  (hardRefresh = false) =>
  (dispatch, getState) => {
    const state = getState();
    const canViewWages = getCanViewWages(state);

    dispatch(
      withAlerts(
        createAsyncGetAction(
          routes.employeesRoute(),
          [
            actionTypes.ROSTER_DATA_REQUEST,
            actionTypes.ROSTER_DATA_SUCCESS,
            actionTypes.ROSTER_DATA_FAILURE,
          ],
          {
            // eslint-disable-next-line no-shadow
            bailout: state =>
              !hardRefresh &&
              (getTeamDataLoaded(state) || getTeamDataIsLoading(state)),
          }
        ),
        {
          onSuccess: res => {
            const numberOfPages = Math.ceil(
              res.payload.user_count / res.payload.num_users_per_page
            );

            const timeoutConst = numberOfPages >= 5 ? 3000 : 0;

            [...Array(numberOfPages).keys()].reduce(
              (promise, numPage) =>
                promise.then(
                  () =>
                    new Promise(resolve => {
                      setTimeout(() => {
                        dispatch(fetchPaginatedUsers(numPage + 1));
                        resolve();
                      }, timeoutConst);
                    })
                ),
              Promise.resolve()
            );

            if (canViewWages)
              fetchJSON(routes.fetchRolesWagesRoutes()).then(rolesWages =>
                dispatch(updateRolesWages({ ...rolesWages }))
              );
          },
        }
      )
    );
  };

// ## Invite Team
export const sendTeamInvites = (locationId, audience) => dispatch =>
  dispatch(
    withAlerts(
      createAsyncPostAction(
        routes.employeesInviteRoute(),
        [
          actionTypes.INVITE_TEAM_REQUEST,
          actionTypes.INVITE_TEAM_SUCCESS,
          actionTypes.INVITE_TEAM_FAILURE,
        ],
        { body: { location_id: locationId, audience } }
      ),
      {
        onSuccess: data => {
          pollWorkerStatus(data.payload.job_id)
            .then(() => {
              dispatch(fetchRosterData(true));
            })
            .catch(() => {
              flashNotice.error(toI18n('errors.generic'));
            });
        },
      }
    )
  );

export const updateInviteTeamAudience = audience => ({
  type: actionTypes.INVITE_TEAM_UPDATE_AUDIENCE,
  payload: audience,
});

// ## UI
export const toggleShowRemoved = () => ({
  type: actionTypes.TOGGLE_SHOW_REMOVED,
});
export const showRemoved = () => ({ type: actionTypes.SHOW_REMOVED });
export const hideRemoved = () => ({ type: actionTypes.HIDE_REMOVED });

export const fetchEmployeesForImport = type =>
  createAsyncGetAction(routes.importedEmployeesRoute({ type }), [
    actionTypes.FETCH_EMPLOYEES_FOR_IMPORT_REQUEST,
    actionTypes.FETCH_EMPLOYEES_FOR_IMPORT_SUCCESS,
    actionTypes.FETCH_EMPLOYEES_FOR_IMPORT_FAILURE,
  ]);

export const updateMatchedEmployee = (id, employee) => ({
  type: actionTypes.UPDATE_MATCHED_EMPLOYEE,
  payload: { id, employee },
});

export const updateMatchedEmployeeWage = (employee, wage, id, applyWage) => ({
  type: actionTypes.UPDATE_MATCHED_EMPLOYEE_WAGE,
  payload: { employee, wage, id, applyWage },
});

export const updateApplyWageForAllMatches = (applyAllWages, existingMatch) => ({
  type: actionTypes.UPDATE_APPLY_WAGE_TO_ALL_MATCHES,
  payload: { applyAllWages, existingMatch },
});

const initializeEmployeeImport = (type, matches, inviteEmployees) =>
  createAsyncPostAction(
    routes.importedEmployeesRoute(),
    [
      actionTypes.INITIATE_IMPORT_EMPLOYEES_REQUEST,
      actionTypes.INITIATE_IMPORT_EMPLOYEES_SUCCESS,
      actionTypes.INITIATE_IMPORT_EMPLOYEES_FAILURE,
    ],
    { body: { type, matches, invite_employees: inviteEmployees } }
  );

const importEmployeesFinished = () => ({
  type: actionTypes.IMPORT_EMPLOYEES_FINISHED,
});

const importEmployeesFinishedAfterExport = () => ({
  type: actionTypes.IMPORT_EMPLOYEES_FINISHED_AFTER_EXPORT,
});

export const importEmployees =
  (matches, inviteEmployees, params) => async dispatch => {
    const { type, adpOnboard, ref } = params;
    const response = await dispatch(
      initializeEmployeeImport(type, matches, inviteEmployees)
    );

    if (response.type === actionTypes.INITIATE_IMPORT_EMPLOYEES_SUCCESS) {
      let flashMessage;
      let flashType;

      try {
        await pollWorkerStatus(response.payload.job_id);
        flashMessage = toI18n('match_employees.success');
        flashType = 'notice';
      } catch (e) {
        flashMessage = e.response.failure_count
          ? toI18n('match_employees.import_error', {
              props: { count: e.response.failure_count },
            })
          : toI18n('match_employees.general_error');
        flashType = 'error';
      } finally {
        if (adpOnboard) {
          window.location = '/dashboard/location';
        } else if (window.location.href.match(/export/)) {
          dispatch(importEmployeesFinishedAfterExport());
          flashMessage = toI18n('match_employees.after_export');
          flashNotice.show(flashType, flashMessage);
          window.Homebase.RailsReactBridge.hardRefresh('/timesheets');
        } else if (ref === 'schedule') {
          window.location = '/schedule_builder';
        } else if (ref === 'department') {
          window.location = '/departments';
        } else {
          await dispatch(fetchRosterData(true));
          browserHistory.push('/team');
          flashNotice.show(flashType, flashMessage);
          dispatch(importEmployeesFinished());
        }
      }
    }
  };

export const removeMatchedEmployee = id => ({
  type: actionTypes.REMOVE_MATCHED_EMPLOYEE,
  payload: { id },
});
