import { fromJS, List, Map, Set } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes as modalActionTypes } from 'actions/modals';
import { actionTypes } from 'actions/teamView';

import { NEW_USER_ID, SKIP_USER_ID } from 'features/employeeView/constants';
import * as constants from 'features/teamView/constants';

import { toI18n } from 'util/i18n';

// ## Invite Team sub-reducer
const INVITE_TEAM_INITIAL_STATE = Map({
  isLoading: false,
  currentView: constants.INVITE_TEAM_VIEWS.FORM,
  audience: constants.INVITE_TEAM_DEFAULT_AUDIENCE_VALUE,
});

const handleSendInvitesFailure = state => ({
  ...state,
  isLoading: false,
  currentView: constants.INVITE_TEAM_VIEWS.FAILURE,
});

const inviteTeam = (state = INVITE_TEAM_INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.INVITE_TEAM_REQUEST:
      // Explicitly handle unavailable server errors until this gets
      // merged: https://github.com/agraboso/redux-api-middleware/pull/26
      if (action.error) {
        return handleSendInvitesFailure(state);
      }
      return state.merge({ isLoading: true });

    case actionTypes.INVITE_TEAM_FAILURE:
      return handleSendInvitesFailure(state);

    case actionTypes.INVITE_TEAM_SUCCESS:
      return state.merge({
        isLoading: false,
        currentView: constants.INVITE_TEAM_VIEWS.SUCCESS,
      });

    case actionTypes.INVITE_TEAM_UPDATE_AUDIENCE:
      return state.merge({ audience: action.payload });

    case modalActionTypes.HIDE_MODAL:
      return state.merge({
        currentView: INVITE_TEAM_INITIAL_STATE.get('currentView'),
      });

    default:
      return state;
  }
};

// ## Resend Employee Invite sub-reducer
const RESEND_EMPLOYEE_INVITES_INITIAL_STATE = Map({
  isLoadingIds: Set(),
});

const addIsLoading = (state, id) =>
  state.updateIn(['isLoadingIds'], ids => ids.add(id));
const removeIsLoading = (state, id) =>
  state.updateIn(['isLoadingIds'], ids => ids.delete(id));
const toggleIsLoading = (add, state, id) =>
  add ? addIsLoading(state, id) : removeIsLoading(state, id);

const resendEmployeeInvites = (
  state = RESEND_EMPLOYEE_INVITES_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.RESEND_EMPLOYEE_INVITE_REQUEST:
      return toggleIsLoading(!action.error, state, action.meta.userId);

    case actionTypes.RESEND_EMPLOYEE_INVITE_SUCCESS:
    case actionTypes.RESEND_EMPLOYEE_INVITE_FAILURE:
      return removeIsLoading(state, action.meta.userId);

    default:
      return state;
  }
};

// ## Resolve Pending Jobs sub-reducer
const RESOLVE_PENDING_JOBS_INITIAL_STATE = Map({
  isLoadingIds: Set(),
});

const resolvePendingJobs = (
  state = RESOLVE_PENDING_JOBS_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.APPROVE_PENDING_JOB_REQUEST:
    case actionTypes.DECLINE_PENDING_JOB_REQUEST:
      return toggleIsLoading(!action.error, state, action.meta.jobId);

    case actionTypes.APPROVE_PENDING_JOB_SUCCESS:
    case actionTypes.DECLINE_PENDING_JOB_SUCCESS:
    case actionTypes.APPROVE_PENDING_JOB_FAILURE:
    case actionTypes.DECLINE_PENDING_JOB_FAILURE:
      return removeIsLoading(state, action.meta.jobId);

    default:
      return state;
  }
};

// ## Roster Data sub-reducer
const ROSTER_DATA_INITIAL_STATE = Map({
  error: null,
  isLoading: false,
  isRolesWagesLoading: false,
  possibleArchiveReasons: [],
  hasRolesAccess: null,
});

const rosterData = (state = ROSTER_DATA_INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ROSTER_DATA_REQUEST:
      return state.merge({ isLoading: true });

    case actionTypes.ROSTER_DATA_SUCCESS:
      return state.merge({
        departments: action.payload.departments,
        possibleArchiveReasons: action.payload.possible_archive_reasons,
        hasRolesAccess: action.payload.has_roles_access,
        showSendPacketButton: action.payload.showSendPacketButton,
        nextEffectivePayday: action.payload.next_effective_payday,
        roleWagesByJobId: action.payload.role_wages_by_job,
        rolesWagesJobIds: action.payload.role_wage_job_ids,
        roleNames: action.payload.role_names,
        userCount: action.payload.user_count,
        numUsersPerPage: action.payload.num_users_per_page,
      });

    case actionTypes.ROSTER_DATA_FAILURE:
      return state.merge({ error: action.payload, isLoading: false });

    case actionTypes.FETCH_PAGINATED_USERS_SUCCESS:
      return state.merge({
        isLoading: false,
      });

    case actionTypes.FETCH_PAGINATED_USERS_FAILURE:
      return state.merge({
        error: action.payload,
        isLoading: false,
      });

    case actionTypes.UPDATE_ROLES_WAGES: {
      return state.merge({
        isRolesWagesLoading: false,
        rolesWages: action.payload.rolesWages,
      });
    }

    default:
      return state;
  }
};

// ## UI sub-reducer
const UI_INITIAL_STATE = Map({
  showRemoved: false,
  queryParams: '',
});

const ui = (state = UI_INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_SHOW_REMOVED:
      return state.merge({ showRemoved: !state.get('showRemoved') });
    case actionTypes.SHOW_REMOVED:
      return state.merge({ showRemoved: true });
    case actionTypes.HIDE_REMOVED:
      return state.merge({ showRemoved: false });
    case actionTypes.UPDATE_QUERY_PARAMS:
      return state.merge(action.payload);
    default:
      return state;
  }
};

const IMPORT_EMPLOYEES_INITIAL_STATE = Map({
  employees: List(),
  jobOptions: List(),
  partnerName: null,
  isImporting: false,
  isFetching: false,
});

const addJobOptions = (jobOptions, label, jobs) => {
  if (jobs.size === 0) {
    return jobOptions;
  }

  jobOptions = jobOptions.push({ value: label, label, disabled: true });
  return jobOptions.concat(
    jobs.map(job => ({ value: job.id, label: job.name }))
  );
};

const importEmployees = (state = IMPORT_EMPLOYEES_INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYEES_FOR_IMPORT_REQUEST:
      return state.merge({ isLoading: true });

    case actionTypes.FETCH_EMPLOYEES_FOR_IMPORT_SUCCESS: {
      const { employees, multi_wage, jobs, partner_name } = action.payload;

      const activeJobs = jobs.filter(job => !job.archived);
      const archivedJobs = jobs.filter(job => job.archived);

      let jobOptions = addJobOptions(
        List(),
        toI18n('match_employees.active_employees'),
        activeJobs
      );

      jobOptions = addJobOptions(
        jobOptions,
        toI18n('match_employees.terminated_employees'),
        archivedJobs
      );

      return state.merge({
        isLoading: false,
        jobs,
        employees: fromJS(employees),
        jobOptions,
        multiWage: multi_wage,
        partnerName: partner_name,
      });
    }

    case actionTypes.FETCH_EMPLOYEES_FOR_IMPORT_FAILURE:
      return state.merge({ isLoading: false });

    case actionTypes.INITIATE_IMPORT_EMPLOYEES_REQUEST:
      return state.merge({ isImporting: true });

    case actionTypes.IMPORT_EMPLOYEES_FINISHED:
      return state.merge({ employees: List(), isImporting: false });

    case actionTypes.INITIATE_IMPORT_EMPLOYEES_FAILURE:
      return state.merge({ isImporting: false });

    case actionTypes.UPDATE_MATCHED_EMPLOYEE: {
      const { id, employee } = action.payload;
      let employees = state.get('employees');

      // Ensure that only a single employee can be matched to the same job id
      if (id !== NEW_USER_ID && id !== SKIP_USER_ID) {
        const existingEmployeeIndex = employees.findIndex(
          e => e.get('match_id') === id
        );

        if (existingEmployeeIndex > -1) {
          employees = employees.setIn(
            [existingEmployeeIndex, 'match_id'],
            'new'
          );
        }
      }

      employees = employees.setIn(
        [employees.indexOf(employee), 'match_id'],
        id
      );

      return state.merge({ employees });
    }

    case actionTypes.UPDATE_MATCHED_EMPLOYEE_WAGE: {
      const { employee, wage, id, applyWage } = action.payload;

      let employees = state.get('employees');
      const employeeIndex = employees.indexOf(employee);

      // Ensure that a job has only one wage with the role
      if (id !== NEW_USER_ID && id !== SKIP_USER_ID) {
        const existingWageIndex = employee
          .get('wages')
          .findIndex(w => w.get('match_id') === id);

        if (existingWageIndex > -1) {
          employees = employees.setIn(
            [employeeIndex, 'wages', existingWageIndex, 'match_id'],
            'new'
          );
        }
      }

      employees = employees.setIn(
        [
          employeeIndex,
          'wages',
          employee.get('wages').indexOf(wage),
          'match_id',
        ],
        id
      );

      employees = employees.setIn(
        [
          employeeIndex,
          'wages',
          employee.get('wages').indexOf(wage),
          'apply_wage',
        ],
        applyWage
      );

      return state.merge({ employees });
    }

    case actionTypes.UPDATE_APPLY_WAGE_TO_ALL_MATCHES: {
      const { applyAllWages, existingMatch } = action.payload;

      let employees = state.get('employees');

      employees.forEach((e, i) => {
        if (e.get('existing_match') !== existingMatch) {
          return;
        }

        e.get('wages').forEach((wage, i2) => {
          if (wage.get('can_apply_wage')) {
            employees = employees.setIn(
              [i, 'wages', i2, 'apply_wage'],
              applyAllWages
            );
          }
        });
      });

      return state.merge({ employees });
    }

    default:
      return state;
  }
};

export default combineReducers({
  inviteTeam,
  resendEmployeeInvites,
  resolvePendingJobs,
  rosterData,
  ui,
  importEmployees,
});
